import React, {
  FunctionComponent,
  useCallback,
  useState,
  ChangeEvent,
  useEffect,
} from "react"
import { Link } from "gatsby"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing-3"
import { useUser } from "../hooks/use-user"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { navigate } from "gatsby"
import { useStyle } from "../hooks/use-style"
import { lightTheme, darkTheme } from "../services/theme"
import { generateMnemonic, validateMnemonic } from "../services/node"
import { useMedia } from "../hooks/use-media"

const LoginPage: FunctionComponent = () => {
  const { user, login1, setType, accountState, setAccountState } = useUser()
  const [secret, setSecret] = useState("")
  const [changed, setChanged] = useState(false)
  const [backup, setSetBackup] = useState(false)
  const [showDonatePrompt, setShowDonatePrompt] = useState(false)
  const [invalidSecret, setInvalidSecret] = useState(false)
  //const { isTouchDevice } = useMedia()

  const isChrome =
    typeof navigator != "undefined"
      ? /Chrome/.test(navigator.userAgent) &&
        /Google Inc/.test(navigator.vendor)
      : false

  const onSubmit = useCallback(
    (event: MouseEvent | null, to: string) => {
      if (secret.length == 0) {
        if (event) event.preventDefault()
        return
      }
      //console.log("onSubmit " + secret)
      /*let field = document.getElementById("password-init")
      if (field.type == "text") {
        field.type = "password"
        event.preventDefault()
        document.getElementById("password-init-submit")?.click()
        return
      }*/

      if (!validateMnemonic(secret)) {
        if (event) event.preventDefault()
        setInvalidSecret(true)
        return
      } else {
        setInvalidSecret(false)
      }

      login1(secret).then(res => {
        if (user?.balance.balance == 0) {
          setAccountState(30)
          navigate("/donate")
        } else {
          setAccountState(15)
          navigate(to)
        }
      })
      if (event) event.preventDefault()
    },
    [secret, accountState, user, setInvalidSecret]
  )

  const onAnimationStart = useCallback(
    (event, closeTo) => {
      //console.log("onAnimationStart " + event.animationName)
      if ("onautofillstart" === event.animationName) {
        onSubmit(null, closeTo)
      }
    },
    [secret]
  )

  const onDonateAgree = useCallback((event: MouseEvent, to: string) => {
    setAccountState(20)
    navigate("/donate")
  }, [])

  const onDonateCancel = useCallback((event: MouseEvent, to: string) => {
    navigate(to)
  }, [])

  const onSubmitAccountType = useCallback(
    (accountType: string, to: string) => {
      console.log(
        "onSubmitAccountType " + user?.account_type + " " + accountType
      )
      if (user?.account_type != accountType) {
        setChanged(true)
        if (accountType == "global") {
          setAccountState(10)
        }
        //setType(accountType)
      }
      if (accountType == "local") {
        setType(accountType)
        setAccountState(-10)
        navigate(to)
      }
    },
    [user]
  )

  const generateKey = useCallback(() => {
    generateMnemonic().then(secret => {
      console.log(secret)
      setSecret(secret)
      if (isChrome == false)
        document.getElementById("password-init").value = secret
      else if (navigator && navigator.clipboard)
        navigator.clipboard.writeText(secret)
      document.getElementById("create-btn").innerHTML =
        "regenerate a new password"
      setSetBackup(true)
    })
  }, [])
  /*
  useEffect(() => {
    if (user?.account_type === "unset")
      setIsSettingUp(false)
}, [user])*/

  const css = useStyle(theme => ({
    borderColor: theme === "light" ? lightTheme.color.border : "transparent",
    inputBackground:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
  }))

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <div className="dialog">
          <div style={{ float: "right", color: "grey" }}>
            <div>
              <Link to={closeTo}>
                <FontAwesomeIcon icon={faTimes} size="lg" />
              </Link>
            </div>
          </div>

          {accountState <= 0 && (
            <div className="header">
              <div
                style={{
                  marginBottom: "30px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Would you like to use a Smartlike account?
              </div>
              <div>
                <div style={{ marginBottom: "10px" }}>
                  It would turn your likes into microdonations to support
                  authors and promote their content in the charts.
                </div>
                Without account your likes will only be saved on this computer
                and used to tune your recommendations.
              </div>
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <button
                  style={{ whiteSpace: "nowrap", width: "200px" }}
                  onClick={e => onSubmitAccountType("global", closeTo)}
                >
                  Login
                </button>
                <button
                  style={{ whiteSpace: "nowrap", width: "200px" }}
                  onClick={e => onSubmitAccountType("local", closeTo)}
                >
                  Proceed without account
                </button>
              </div>
            </div>
          )}

          {accountState > 0 && showDonatePrompt == false && (
            <>
              {accountState < 15 && !changed ? (
                <div className="header">
                  <p>
                    Smartlike respects your privacy and uses secret account keys
                    to generate cryptographic signatures for user likes and
                    donations.
                  </p>
                  <p>
                    Please create a new key or enter one you generated
                    previously. Back it up and store in your favorite password
                    manager.
                  </p>
                </div>
              ) : (
                <div className="header">
                  Please submit your Smartlike account key or create a new one.
                </div>
              )}

              <form className="custom-form" method="post">
                <div className="input-container">
                  <input
                    id="password-init"
                    className="text-input"
                    type="password"
                    name="account_key"
                    ref={input => {}}
                    onChange={evt => setSecret(evt.target.value)}
                    onAnimationStart={evt => onAnimationStart(evt, closeTo)}
                  />
                </div>
                {invalidSecret && (
                  <div
                    style={{
                      marginTop: "5px",
                      color: "red",
                      fontFamily: "Roboto",
                    }}
                  >
                    Invalid secret
                  </div>
                )}
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <button
                    id="password-init-submit"
                    type="submit"
                    onClick={e => onSubmit(e, closeTo)}
                  >
                    Submit
                  </button>
                  <div
                    id="create-btn"
                    onClick={generateKey}
                    className="generate"
                  >
                    generate new password
                  </div>
                </div>
                {backup == true && (
                  <>
                    <div
                      style={{
                        fontFamily: "Roboto",
                        textAlign: "left",
                        marginTop: "10px",
                      }}
                    >
                      {isChrome ? (
                        <>
                          The below key has been copied to clipboard, paste it
                          in the box above and save it in your password manager:
                        </>
                      ) : (
                        <>
                          Please keep your account key in the password manager
                          and make a back up:
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        marginTop: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      <code>{secret}</code>
                    </div>
                  </>
                )}

                {/*isChrome && isTouchDevice == false && (
                  <div className="info">
                    You can install{" "}
                    <a
                      className="info"
                      href="https://blog.smartlike.org/extension-coming-soon"
                    >
                      browser extension
                    </a>{" "}
                    to skip this prompt.
                  </div>
                )*/}
              </form>
            </>
          )}

          <style jsx>{`
          .generate {
            cursor: pointer;
            /*display: inline;
            margin-left: 20px;*/
            text-decoration: underline;
            line-height: 14px;
          }
          .custom-form {
            margin-top: 30px;
          }
          .options {
            line-height: 22px;
          }
            .dialog {
              max-width: 600px;
              width: 100%;
              z-index: 20000;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              padding: 20px;
              /*background-color: #eeeeee;*/
              border: 1px solid ${css.borderColor};
              border-radius: 5px;

              background-color: #fff;
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                0 6px 20px 0 rgba(0, 0, 0, 0.19);
            }
            .header {
              margin-top: 40px;
              font-family: Roboto;
            }
            h2 {
              text-align: center;
              font-size: 20px;
              font-family: Roboto;
            }
            button {
              padding: 5px;
              margin: 10px;
              width: 80px;
            }

            .input-container {
              flex: 1;
              /*border-radius: 2em;*/
              background-color: ${css.inputBackground};
              border: 1px solid ${css.borderColor};
              transition: 200ms background-color, 200ms border-color,
                200ms box-shadow;
              display: flex;
              position: relative;
              margin-top: 10px;
            }
            .text-input {
              width: 100%;
              height: 100%;
              margin: auto;
              background-color: ${css.inputBackground};
              color: inherit;
              border: none;
              padding-left: 0.5em;
              padding-right: 0.5em;
              flex: 1;
              /*border-top-left-radius: 0.3em;
              border-bottom-left-radius: 0.3em;*/
              font: inherit;
              outline: none;
            }
            .info {
              /*text-decoration: none;*/
              margin-top: 14px;
              font-size: 14px;
            }
            .info-link {
              color: blue;
            }
            .radio-input {
              margin-right: 10px;
            }
            .choice {
              /*padding: 5px;*/
              display: inline;
              outline: none;
            }
            .choice:hover {
              cursor: pointer;
              /*background-color: ${css.inputBackground};*/
            }
          `}</style>
        </div>
      )}
    </ModalRoutingContext.Consumer>
  )
}

export default LoginPage
